/**
 * nCompass: Client Document Soft Removal Modal
 * @author Miquel Brazil
 */
import StateManager from "./SoftRemovModal/StateManager";
import States from "./SoftRemovModal/States";

export default () => ({
    Modal: null,
    StateManager: null,
    step: null,
    status: {
        label: null,
        message: null,
    },
    Document: {
        id: null,
    },
    init() {
        this.StateManager = StateManager(States("Closed"));
        this.Modal = new Modal(this.$el, {
			closable: false
		});
        this.StateManager.state.current = this.StateManager.getInitalStateFor();
    },
    actions: {
        showConfirmationModal() {
            console.log("Showing Soft Remove Modal");
            this.step = 1;
            this.Modal.show();
        },
    },
    transition(on) {
        if (this.StateManager.transition.isValid.apply(this, [on])) {
            const current = this.StateManager.getCurrentState();
            const next = this.StateManager.getNextStateFor(current, on);

            this.StateManager.transition.fromState.apply(this, [current]);
            this.invoke(
                this.StateManager.transition.toState.apply(this, [next])
            );
        }
    },
    invoke(transition) {
        console.log(transition);
        if (transition) {
            this.actions[transition].apply(this);
        }
    },
    confirm(event) {
        this.Document.id = event.target.closest("tr").dataset.ncDocumentId;
        this.step = 1;
        this.Modal.show();
    },
    rationalize() {
        this.step = 2;
    },
    remove(event) {
        this.step = 3;
        this.status.label = "processing";
        this.status.message = "Deleting document...";

        let form = new FormData(event.target);

        fetch("/api/v0/clients/admin/remove-document", {
            method: "POST",
            body: form,
        })
            .then((response) => response.json())
            .then((data) => {
                setTimeout(() => {
                    this.status.label = "success";
                    this.status.message = "Document successfully deleted.";

                    this.hide();

                    this.$dispatch("ncompass:remove-document-from-table", {
                        Document: this.Document,
                    });
                }, 1500);
            });
    },
    hide() {
        this.Modal.hide();

		let form = this.$el.closest('form');
		if (form) {
			form.reset();

			let reasonSelector = form.querySelector('#selectReason')
			if (reasonSelector) {
				reasonSelector._x_dataStack[0].reason = 0;
			}
		}
    },
});
