/**
 * nCompass - client-profile-program-row.js
 * @author Miquel Brazil
 *
 * @todo: add comment about why we're using the lesser preferred pattern for creating component IDs here
 */

export default (provider) => ({
    Program: {
        ref: null,
        Provider: {
            name: provider
        }
    },
    actions: {
        default: 0,
        selected: null
    },
    init() {
        this.Program.ref = this.$id('client-profile-program');
        this.setDefaultAction();
    },
    doAction() {
        if (this.actions.selected == 1) {
            this.$dispatch('ncompass:show-update-service-manager-modal', {
                    Client: this.$data.Client,
                    Program: this.Program
                }
            );
        }
    },
    setDefaultAction() {
        this.actions.selected = this.actions.default;
    },
    resetActions() {
        if (this.$event.detail.Program.ref === this.Program.ref) {
            this.setDefaultAction();
        }
    }
})
