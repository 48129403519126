/**
 * nCompass - client-data-field-update-modal.js
 * @author Miquel Brazil
 */

export default () => ({
    Modal: null,
	Field: {
		id: null,
		ref: null,
		label: null,
		to: null,
		from: null,
		input: null,
		reason: null
	},
	defaults: [
		null, 0, ''
	],
	completed: {
		value: false,
		reason: false
	},
	ready: false,
	isDefault(value) {
		return this.defaults.includes(value) || false; //might want to consider stripping whitespace
	},
	checkCompleted() {
		this.ready = Object.values(this.completed).every(item => item === true) || false;
	},
    init() {
        this.Modal = new Modal(this.$el);
    },
    show() {
		console.log(this.$event.detail.field);
		this.Field = {...this.Field, ...this.$event.detail.field};
        this.Modal.show();
    },
    hide() {
        this.Modal.hide();
        this.reset();
    },
    update() {
		let data = new FormData(this.$event.target);

        fetch('/api/v0/clients/admin/update-data-field', {
			method: 'POST',
			body: data
		}).then(
			(response) => {
                 if (response.ok) {
                     this.hide();
                     window.location.reload();
                 }
             }
        );
	},
    reset() {
        this.Field = {
			id: null,
			ref: null,
			label: null,
			to: null,
			from: null,
			input: null,
			reason: null
		};

		this.completed.value = false;
		this.completed.reason = false;
    }
})
