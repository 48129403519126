/**
 * nCompass - client-data-point-tools-select.js
 * @author Miquel Brazil
 */

export default () => ({
	action: null,
	doAction: {
		['@change']() {
			this.$dispatch(
				'ncompass:show-client-data-field-update-modal',
				{
					/** prefixing each property here to make it clear it is coming from a parent element */
					field: {
						id: this.$data.id,
						label: this.$data.label,
						formatted: this.format(this.$data.value),  // this is the formatted value to display in the UI
						from: this.getRawValues(this.$data.value),  // this is the raw value that will be saved as the original value in the database
						input: (typeof this.$data.input !== 'undefined') ? this.$data.input : 'text',
						ref: this.$data.ref,
						options: ((this.$data.input === 'select' || this.$data.input === 'multi-select') && (typeof this.$data.options !== 'undefined')) ? this.$data.options : []
					}
				}
			);

			/** reactive updates within a Alpine component need to be done on the nextTick */
			this.$nextTick(() => { this.action = null });
        }
	},
	format(field) {
		console.log(field);
		let output = null;

		if (field !== null && typeof field === 'object' && typeof field.name !== 'undefined' && field.name === 'race') {
			const races = {
				'1': 'American Indian or Alaska Native',
				'2': 'Asian',
				'3': 'Black or African American',
				'4': 'Native Hawaiian or Other Pacific Islander',
				'5': 'White'
			};

			if (typeof field.values === 'string') {
				output = field.values;
			} else if (Array.isArray(field.values)) {
				output = [];
				field.values.forEach((race) => {
					if (typeof races[race] !== 'undefined') {
						output.push(races[race]);
					} else {
						output.push(race);
					}
				});
				output = output.join(',<br />');
			}
		} else if (field !== null && typeof field === 'object' && typeof field.format !== 'undefined') {
			switch (field.format) {
				case 'phone-with-type':
					var number = field.values['phone-number'] || field.values['phone.number'] || field.values['emergency-contact.phone.number'];
					var type = field.values['phone-type'] || field.values['phone.type'] || field.values['emergency-contact.phone.type'];

					if (number) {
						output = number;
						output += type ? '<span class="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">' + type + '</span>' : '';
					} else {
						output = '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">No Data</span>';
					}

					break;
				case 'address':
					var required = {...field.values};
					delete required['residence.line-2'];
					console.log(required);
					if (!Object.values(required).some(value => value === null)) {
						output = field.values['residence.line-1'] + '<br />';
						output += field.values['residence.line-2'] ? field.values['residence.line-2'] + '<br />' : '';
						output += field.values['residence.city'] + ', ' + field.values['residence.state'] + ' ' + field.values['residence.zip'];
					} else if (Object.values(required).some(value => value === null)) {
						output = '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Invalid Data</span>';
					} else if (Object.values(required).every(value => value === null)) {
						output = '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">No Data</span>';
					}

					break;
				case 'seropositivity': {
					let values = {
						'10': 'HIV Positive',
						'20': 'HIV Negative',
						'99': 'Unknown'
					}

					output = values[field.value.toString()];
					break;
				}
				case 'insurance-status': {
					output = field.values['medical-insurance-status'] ? 'Insured' : 'Uninsured';
					break;
				}
				case 'insurance-type': {
					output = field.values['medical-insurance-type'] || '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">No Data</span>';
					break;
				}
				case 'insurance-provider': {
					output = field.values['medical-insurance-type'] || '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">No Data</span>';
					break;
				}
				case 'ahf': {
					output = field['value-raw'] ? '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Yes</span>' : '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">No</span>';
					break;
				}
				case 'nym': {
					if ((typeof field.values['emergency-contact.nym.given'] !== 'undefined' && field.values['emergency-contact.nym.given'] !== null) && (typeof field.values['emergency-contact.nym.surname'] !== 'undefined' && field.values['emergency-contact.nym.surname'] !== null)) {
						output = field.values['emergency-contact.nym.given'] + ' ' + field.values['emergency-contact.nym.surname'];
					} else {
						output = '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">No Data</span>';
					}
					break;
				}
				default: {
					if (field.value) {
						output = field.value;
					} else {
						output = '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">No Data</span>';
					}
				}
			}
		} else if (field === null) {
			output = '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">No Data</span>';
		}

		return output || field;
	},
	getRawValues(field) {
		let values = null;

		if (field !== null && typeof field === 'object') {
			if (typeof field['value-raw'] !== 'undefined' && field['value-raw'] !== null) {
				values = field['value-raw'];
			} else if (typeof field.value !== 'undefined') {
				values = field.value;
			} else if (typeof field.values !== 'undefined') {
				values = field.values;
			}
		} else {
			values = field;
		}

		return values;
	}
})
