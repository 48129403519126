/**
 * nCompass - Document Soft Remove Modal States
 * @author Miquel Brazil
 */
function parseStateString(state) {
    if (state === null || typeof state !== "string") {
        return null;
    }

    console.log(state);

    return state.split(".");
}

export default (states) => ({
    states: states,
    state: {
        initial: null,
        current: null,
    },
    transition: {
        isValid: function (event) {
            console.log(this);
            const currentState = this.StateManager.getCurrentState();
            console.log(currentState);
            if (
                this.StateManager.getTransitionsFor(currentState).includes(
                    event.toUpperCase()
                )
            ) {
                return true;
            }

            return false;
        },
        isInitialState: function (stateString) {
            const state = parseStateString(stateString);
            console.log(state);
            if (state[state.length - 1] === "initial") {
                console.log("This is an initial state");
                return true;
            }

            return false;
        },
        fromState: function (currentState) {
            console.log("Transitioning from", currentState.name);
        },
        toState: function (nextStateString) {
            console.log("Transitioning to", nextStateString);
            let nextState = null;

            if (this.StateManager.transition.isInitialState(nextStateString)) {
                console.log(this);
                const rootState =
                    this.StateManager.getRootState(nextStateString);
					console.log(rootState);
                const initialState =
                    this.StateManager.getInitalStateFor(rootState);
                console.log(initialState);
                nextState = this.StateManager.getState(initialState);
            } else {
                nextState = this.StateManager.getState(nextStateString);
            }

            this.StateManager.state.current = nextState.name;
            console.log(nextState);

			if ('actions' in nextState && 'onEnter' in nextState.actions) {
            	return nextState.actions.onEnter;
			} else {
				return null;
			}
        },
    },
    getRootState(stateString) {
        const state = parseStateString(stateString);
        return state[0];
    },
    getTransitionsFor(state) {
        if (state !== null && "on" in state && typeof state.on === "object") {
            return Object.keys(state.on).map((event) => event.toUpperCase());
        }

        return [];
    },
    getCurrentState() {
        let state = this.getState(this.state.current);
        return state;
    },
    getNextStateFor(current, event) {
        return current.on[event.toLowerCase()];
    },
    getState(_state) {
        const state = parseStateString(_state);
        console.log(state);
        if (state) {
            if (state.length === 1 && state[0] in this.states) {
                return this.states[state[0]];
            } else {
                return this.getStateByArrayPath(state);
            }
        }

        return state;
    },
    /** @param {Array} statePath */
    getStateByArrayPath(statePath) {
        let state = null;

        statePath.forEach((s) => {
            if (state === null && s in this.states) {
                state = this.states[s];
            } else if (
                state !== null &&
                "states" in state &&
                s in state.states
            ) {
                state = state.states[s];
            }
        });

        return state;
    },
    getInitalStateFor(_state = null) {
		let state = _state;

		if (state === null && 'initial' in this.states && this.states.initial !== null) {
			state = this.states.initial;
		}

		if (state !== null && state in this.states) {
			return state + "." + this.states[state].initial.name;
		}

        return null;
    },
});
