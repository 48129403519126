/**
 * nCompass - search.js
 * @author Miquel Brazil
 */

export default () => ({
	rewriteQuery(e) {
		/** query is being rewritten during the FormData event */

		let searchType = e.formData.get('search[type]').trim();
		let searchTerms = e.formData.get('search[terms]').trim();

		e.formData.delete('search[type]');
		e.formData.delete('search[terms]');

		e.formData.set(searchType, searchTerms);
	}
})
