export default () => ({
    Modal: null,
    Client: {
        node: null
    },
    Program: {
        name: null,
        ref: null,
        currentProvider: {
            name: null
        },
        newProvider: {
            node: null
        }
    },
    init() {
        this.Modal = new Modal(this.$el);
    },
    show() {
        this.Client = { ...this.$event.detail.Client};
        this.Program.currentProvider.name = this.$event.detail.Program.Provider.name;
        this.Program.ref = this.$event.detail.Program.ref;
        this.Modal.show();
    },
    hide() {
        this.$dispatch('ncompass:reset-client-profile-program-actions', {
            Program: {
                ref: this.Program.ref
            }
        });

        this.Modal.hide();

        this.Program.newProvider.node = null;
    },
    update() {
		let data = new FormData();

		data.append('Client[node]', this.Client.node);
		data.append('Provider[node]', this.Program.newProvider.node);

        fetch('/api/v0/clients/admin/assign-service-manager', {
			method: 'POST',
			body: data
		}).then(response => {
			if (response.ok) {
                    this.Modal.hide();
                    window.location.reload();
                }
            }
		);
    }
})
