/**
 * nCompass - Document Soft Remove Modal States
 * @author Miquel Brazil
 */

export default (initialState = null) => {
	const Closed = {
		states: {
			IDLE: {
				name: "IDLE",
				id: -1,
				on: {
					delete: "Opened.initial"
				}
			},
		}
	};
	Object.freeze(Closed);

	let Opened = {
		states: {
			CONFIRMING: {
				name: "CONFIRMING",
				id: 1,
				on: {
					confirm: "DELETING",
					cancel: "Closed.IDLE"
				},
				actions: {
					onEnter: 'showConfirmationModal'
				}
			},
			PROVIDE_RATIONALE: {
				"name": "PROVIDE_RATIONALE",
				"id": 2
			},
			"DELETING": 3,
			"DELETE_SUCCESS": 4,
			"DELETE_FAILURE": 5,
			"RELOADING": 6,
			"CANCELING": 10
		}
	};
	Object.freeze(Opened);

	return {
		initial: initialState,
		Closed: {
			initial: Closed.states.IDLE,
			states: Closed.states
		},
		Opened: {
			initial: Opened.states.CONFIRMING,
			states: Opened.states
		}
	};
};
