/**
 * nCompass: Client Service Status
 * @author Miquel Brazil
 */

export default (status, label) => ({
	Service: {
		status: null,
		label: null,
	},
	init() {
		this.Service.status = status
		this.Service.label = label
	},
	getLabel() {
		return this.Service.label
	},
	isActive() {
		return this.isClient('active')
	},
	isInactive() {
		return this.isClient('inactive')
	},
	isDeceased() {
		return this.isClient('deceased')
	},
	isDemo() {
		return this.isClient('demo')
	},
	isDuplicate() {
		return this.isClient('duplicate')
	},
	isClient(status) {
		return (this.getLabel()).toLowerCase() === status
	}
});
