/**
 * nCompass - client-document-upload-modal.js
 * @author Miquel Brazil
 */

export default (client, provider, types) => ({
    Modal: null,
    Client: client,
    Provider: provider,
    Document: {
        dates: {
            service: null
        },
        category: null,
        type: null,
        notes: ''
    },
    Types: types,
    Files: [],
    init() {
        this.Modal = new Modal(this.$el);
    },
    show() {
        this.Modal.show();
    },
    hide() {
        this.Modal.hide();
        this.reset();
    },
    upload() {
        let form = new FormData();
		console.log(this.Files[0]);
        this.Files.forEach((file) => { form.append('files[]', file); });    // @todo: research why the array key requires []

        form.append('document', JSON.stringify(this.Document));
        form.append('client', JSON.stringify(this.Client));
        form.append('provider', JSON.stringify(this.Provider));

        fetch('/api/v0/clients/admin/upload-document', {
			method: 'POST',
			body: form
		}).then(
			(response) => {
                 if (response.ok) {
                     this.hide();
                     window.location.reload();
                 }
             }
        );
    },
    setFile() {
        const files = this.$event.target.files;

        if (files.length > 0) {
            this.Files = [...files];
        }
    },
    reset() {
        this.Document.dates.service = null;
        this.Document.category = null;
        this.Document.type = null;
        this.Document.notes = '';
    }
})
