/**
 * nCompass - date-select-input.js
 * @author Miquel Brazil
 */

import Datepicker from 'flowbite-datepicker/Datepicker';

export default () => ({
    Calendar: null,
    init() {
        // create parameter for input element ID
        this.Calendar = new Datepicker(this.$el.querySelector('#selectDocumentServiceDate'), {
            autohide: true,
			container: '#documentUploadDatePicker'
        });

        this.Calendar.pickerElement.addEventListener('click', function($input, e) {
            if (typeof e.target.dataset.date !== 'undefined') {
                let dateChangeEvent = new Event('dateChange');
                $input.dispatchEvent(dateChangeEvent);
            }
        }.bind(this.Calendar, this.Calendar.inputField));

		this.Calendar.pickerElement.addEventListener('click', function(e) {
			console.log(e);
			console.log(this.picker);
			if (this.picker.active) {
				this.picker.main.querySelectorAll('.datepicker-view, .datepicker-grid').forEach((el) => {
					el.classList.remove('w-64');
				})
			}
		}.bind(this.Calendar));

		this.Calendar.pickerElement.classList.add('w-full', 'flex', 'justify-stretch', 'content-stretch');
		this.Calendar.pickerElement.querySelector('.datepicker-picker').classList.add('w-full');
		this.Calendar.pickerElement.querySelector('.days').classList.add('w-full');

		this.Calendar.pickerElement.querySelectorAll('.datepicker-view, .datepicker-grid').forEach((el) => {
			el.classList.remove('w-64');
		});

        this.Calendar.inputField.addEventListener('dateChange', function(e) {
            e.target._x_model.set(e.target.value);
        });
    }
})
